import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { accent } from "../utilities"
import GlobalStyle from "../css/Global"
import SEO from "../components/seo"
import Img from "gatsby-image"

import styled from "styled-components"

const IndexPage = ({ data }) => {

  const site = data.site.siteMetadata;

  return (
    <StyledOuterWrapper>
      <SEO
        title={site.title}
        description={data.homePage.frontmatter.metaDescription}
      />
      <GlobalStyle />
      <img src="/assets/platform-architecture-logo-black.png" style={{ width: "500px" }} alt={site.title + ' logo'} />
      <StyledLinks>
        <StyledPageLink to="/">Home</StyledPageLink>
        <StyledPageLink to="/about">About</StyledPageLink>
        <StyledPageLink to="/projects">Projects</StyledPageLink>
        <StyledPageLink to="/contact">Contact</StyledPageLink>
      </StyledLinks>
      <StyledLinks className="contacts">
        <StyledContactLink href={`tel:${site.telephone}`}>{site.telephoneReadable}</StyledContactLink>
        <StyledContactLink href={`mailto:${site.email}`}>{site.email}</StyledContactLink>
      </StyledLinks>
     <StyledMainImg fluid={data.homePage.frontmatter.mainImage.childImageSharp.fluid}></StyledMainImg>

    </StyledOuterWrapper>
  )
}


const StyledOuterWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
  position:relative;
  padding:20px;
  
`

const StyledLinks = styled.div`
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    margin-top:20px;
    flex-wrap:wrap;
`

const StyledPageLink = styled(Link)`
  color:#000;
  font-size: 1.2rem;
  text-transform:uppercase;
  text-decoration:none;
  margin:0.5rem 1rem;

 
  &:hover {
    color: ${accent};
  }
`

const StyledMainImg = styled(Img)`
  width:100%;
  max-width:1200px;
  margin:30px auto 0;
`


const StyledContactLink = styled.a`
color:#000;
font-size: 1.2rem;
text-decoration:none;
margin: 0 1rem;
&:hover {
  color: #000;
}
`

export const HOME_PAGE_QUERY = graphql`
query {
  site: site{
    siteMetadata {
        title
        businessAddress
        telephone
        telephoneReadable
        email
      }
  }
  homePage: markdownRemark(frontmatter: {templateKey: {eq: "home-page"}}) {
    frontmatter {
      metaDescription
      mainImage {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
}
`

export default IndexPage
